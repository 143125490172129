<template>
  <div>
    <ckeditor
      type="classic"
      v-model="conteudo"
      :config="editorConfig"
      :editor="editor"
    />
  </div>
</template>

<script>

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js";
import { UploadAdapter } from "../../../../helpers/adapters";

export default {
  name: "EstruturaConteudo",
  components: {
    ClassicEditor
  },
  props: [
    'value',
    'formData'
  ],
  data() {
    return {
      conteudo: "",
      editor: ClassicEditor,
      editorConfig: {
        language: "pt-br",
        extraPlugins: [this.uploadImage],
      }
    }
  },
  beforeMount() {
    // Se a estrutura é de string
    if (typeof this.value === 'string') {
      this.conteudo = this.value;
      return;
    }

    // Tratativa para modificar a estrutura para a correta de tabela
    this.conteudo = "";
  },
  methods: {
    // Criado adapter para upload de imagens 
    uploadImage(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },
  },
  watch: {
    conteudo (novoConteudo) {
      this.$emit('input', novoConteudo);
    }
  }
}
</script>