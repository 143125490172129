<template>
    <div>
        <Loading :loading="loading" />
        <ModalEdit
            :formSchema="formSchema"
            :formInputs="formInputs"
            :editingItem="editingItem"
            :onSubmit="onSubmit"
            :alertMessage="alertMessage"
        >
          <template v-slot:edit-buttons="{model}">
            <slot name="edit-buttons" :model="model"></slot>
          </template>
        </ModalEdit>
        <ModalShow
            :editingItem="editingItem"
        />
        <div class="row">
            <div class="col-12" v-if="alerts">
              <div class="alert alert-info" style="margin: 0;">
                <strong>ATENÇÃO:</strong> {{ alerts }}
              </div>
            </div>
            <div v-if="enableSearch" v-bind:class="{'col-6': enableSearch}">
                <span class="filters__search">
                    <input
                      type="text"
                      v-model="termo"
                      placeholder="Buscar por ..."
                      v-on:input="debounceLoadData()"
                    />
                    <fa-icon icon="search" />
                </span>
                <slot name="filter-search" />
            </div>
            <div class="text-right header-buttons" v-bind:class="{'col-6': enableSearch, 'col-12': !enableSearch}">
                <CButton color="primary" @click="onWillStore" size="sm" v-if="onStore != null">
                    Novo registro
                </CButton>
                <slot name="header-buttons" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table-paginated
                    :itens="itens"
                    :columns="columns"
                    :paginator="paginator"
                    :onPageChange="loadData"
                    :onDelete="onDelete != null ? onWillDelete : null"
                    :canDelete="canDelete"
                    :onUpdate="onUpdate != null ? onWillUpdate : null"
                    :canUpdate="canUpdate"
                    :onShow="onShow != null ? onWillShow : null"
                    :onClone="onClone != null ? onWillClone : null"
                    :isLoadingData="loadingData"
                    :showActions="showActions"
                >

                    <template v-for="column in columns" v-slot:[column.field]="{model}">
                      <slot :name="column.field" :model="model"></slot>
                    </template>

                    <template v-slot:action-buttons="{model}">
                        <slot name="action-buttons" :model="model"></slot>
                    </template>

                    <template v-slot:action-buttons-after="{model}">
                        <slot name="action-buttons-after" :model="model"></slot>
                    </template>
                </table-paginated>
            </div>
        </div>
    </div>
</template>

<script>
  import clone from 'just-clone';
  import { get } from '@/helpers/apiRequest';
  import TablePaginated from './TablePaginated'
  import { confirmDelete, confirmUpdate, confirmStore } from "@/helpers/crud";
  import ModalEdit from "./ModalEdit";
  import ModalShow from "./ModalShow";
  import _debounce from "lodash/debounce";
  import _reduce from "lodash/reduce";

  export default {
    name: "CrudBase",
    components: {
      ModalEdit,
      ModalShow,
      TablePaginated
    },
    props: [
      'url',
      'columns',
      'onDelete',
      'canDelete',
      'onUpdate',
      'canUpdate',
      'onStore',
      'onShow',
      'onClone',
      'formInputs',
      'enableSearch',
      'primaryKey',
      'alertMessage',
      'checkAlerts',
      'transform',
      'transformStore',
      'transformUpdate',
      'showActions',
      'reload',
      'useQueryString',
      'filters',
    ],
    data() {
      return {
        editingItem: {},
        itens: [],
        alerts: '',
        termo: '',
        paginator: {},
        loading: false,
        loadingData: false,
        currentAction: 'store',
        formSchema: null,
      }
    },
    computed: {
      filtersQuery() {
        return _reduce(this.filters, (acc, value, key) => {
          if (value) {
            acc += `&${key}=${value}`;
          }
          return acc;
        }, "");
      },
    },
    methods: {
      debounceLoadData: _debounce(function() {
        this.loadData()
      }, 300),
      loadData(page = 1, take = 15) {
        this.loadingData = true;

        get(`${this.url}?q=${this.termo}&page=${page}&take=${take}${this.filtersQuery}`)
          .then(json => {
            const { itens, paginator } = json;

            let transformedItems = itens;
            if (this.transform) {
              transformedItems = itens.map(item => this.transform({ ...item }));
            }

            this.itens = transformedItems;
            this.paginator = paginator;
            this.loadingData = false;
          })
          .catch(() => {
            this.loadingData = false;
          });
      },
      loadAlerts() {
        if (this.checkAlerts) {
          get(`${this.url}/check-alertas`)
              .then(json => this.alerts = json.message)
        }
      },
      onWillDelete(row) {
        if (this.onDelete) {
          return confirmDelete(
              `${this.url}/${row[this.primaryKey || 'id']}`,
              this.loadData
          )
        }
      },
      async onWillUpdate(row) {
        if (this.onUpdate) {
          this.editingItem = clone(row);
          this.currentAction = 'update';
          await this.loadFormSchema();
          return this.$modal.show('crud-edit');
        }
      },
      async onWillStore() {
        if (this.onStore) {
          this.editingItem = {};
          this.currentAction = 'store';
          await this.loadFormSchema();
          return this.$modal.show('crud-edit');
        }
      },
      onWillShow(row) {
        if (this.onShow) {
          this.editingItem = row;
          return this.$modal.show('crud-show');
        }
      },
      async onWillClone(row) {
        if (this.onClone) {
          this.editingItem = clone(row);
          delete this.editingItem[this.primaryKey];
          this.onClone(this.editingItem);
          await this.loadFormSchema();
          return this.$modal.show('crud-edit');
        }
      },
      onSubmit(row) {
        /// On Success submit
        const onSuccess = () => {
          this.loadData();
          this.$modal.hide('crud-edit');
        }

        /// perform an update
        if (this.currentAction === 'update') {
          if (this.transformUpdate) {
            row = this.transformUpdate(row);
          }
          return confirmUpdate(`${this.url}/${row[this.primaryKey || 'id']}`, row, onSuccess);
        }

        /// perform an insert
        if (this.transformStore) {
          row = this.transformStore(row);
        }
        return confirmStore(`${this.url}`, row, onSuccess);
      },
      async loadFormSchema() {
        if (!!this.formSchema) {
          return;
        }

        if (!!this.formInputs) {
          this.formSchema = {
            type: "object",
            properties: this.formInputs,
            value: this.editingItem || {},
          }
          return;
        }

        this.loading = true;
        await get(`${this.url}/create`).then( response => {
          this.formSchema = {
            type: "object",
            properties: response,
            value: {},
          }
          this.loading = false;
        });
      }
    },
    watch: {
      filters: {
        handler(newValue) {
          this.loadData();
        },
        deep: true,
      },
      "termo": function(newValue) {
        if (this.useQueryString || this.useQueryString === undefined) {
          history.pushState({}, null, this.$route.path + '?q=' + (newValue || ''));
        }
      },
      "reload": function (newValue, oldValue) {
        if (newValue) {
          this.loadData();
          this.$emit('reload', false);
        }
      },
      "itens": function (newValue) {
        this.$emit('itens', newValue)
      }
    },
    beforeMount() {
      if (this.useQueryString || this.useQueryString === undefined) {
        this.termo = this.$route.query.q || '';
      }
      this.loadData();
      this.loadAlerts();
    },
  }
</script>

<style scoped>
.header-buttons button {
  margin-left: 5px;
}
</style>