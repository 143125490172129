// Para buscar ícones, consultar em : https://coreui.io/icons/all/
import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay, 
  cibCcAmex
} from '@coreui/icons'

import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'

import {
  cilArrowRight,
  cilArrowThickBottom,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCommentBubble,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilAddressBook,
  cilSitemap,
  cilGroup,
  cilBullhorn,
  cilBadge,
  cilBriefcase,
  cilChatBubble,
  cilLink,
  cilMobile,
  cilPaint,
  cilSave,
  cilTag,
  cilTags,
  cilTrash,
  cilTerminal,
  cilSofa,
  cilListRich,
  cilBuilding,
  cilLibrary,
  cilMoney,
  cilNewspaper,
  cilListLowPriority,
  cilListHighPriority,
  cilThumbUp,
  cilCog,
  cilListNumbered,
  cilBarChart,
  cilChartLine,
  cilBook,
  cilImagePlus,
  cilHistory,
  cilVector,
  cilRoom,
  cilCalendarCheck,
  cilSwapHorizontal,
  cilMediaStepForward,
  cilPlus,
  cilWarning,
  cilScreenSmartphone,
  cilScreenDesktop,
  cilClock,
  cilPlaylistAdd,
  cilCode,
  cilColumns,
  cilViewColumn,
  cilNotes,
  cilTruck,
  cilLockUnlocked,
  cilSquare,
  cilClone,

} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cilLockUnlocked,
    cilSquare,
    cilArrowRight,
    cilArrowThickBottom,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCommentBubble,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilAddressBook,
    cilSitemap,
    cilGroup,
    cilBullhorn,
    cilBadge,
    cilBriefcase,
    cilChatBubble,
    cilLink,
    cilMobile,
    cilPaint,
    cilSave,
    cilTag,
    cilTags,
    cilTrash,
    cilTerminal,
    cilSofa,
    cilListRich,
    cilBuilding,
    cilLibrary,
    cilMoney,
    cilNewspaper,
    cilListLowPriority,
    cilListHighPriority,
    cilThumbUp,
    cilCog,
    cilListNumbered,
    cilBarChart,
    cilChartLine,
    cilBook,
    cilImagePlus,
    cilHistory,
    cilVector,
    cilRoom,
    cilCalendarCheck,
    cilSwapHorizontal,
    cilMediaStepForward,
    cilPlus,
    cilWarning,
    cilScreenSmartphone,
    cilScreenDesktop,
    cilClock,
    cilPlaylistAdd,
    cilCode,
    cilColumns,
    cilViewColumn,
    cilNotes,
    cilTruck,
    cilClone,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard, 
    cibCcVisa, 
    cibStripe, 
    cibPaypal, 
    cibGooglePay,
    cibCcAmex
  }
)
