import { render, staticRenderFns } from "./CloseModal.vue?vue&type=template&id=e1081694&scoped=true"
import script from "./CloseModal.vue?vue&type=script&lang=js"
export * from "./CloseModal.vue?vue&type=script&lang=js"
import style0 from "./CloseModal.vue?vue&type=style&index=0&id=e1081694&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1081694",
  null
  
)

export default component.exports