import { render, staticRenderFns } from "./TablePaginated.vue?vue&type=template&id=05f2c981&scoped=true"
import script from "./TablePaginated.vue?vue&type=script&lang=js"
export * from "./TablePaginated.vue?vue&type=script&lang=js"
import style0 from "./TablePaginated.vue?vue&type=style&index=0&id=05f2c981&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f2c981",
  null
  
)

export default component.exports