export class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then((file) => {
      return new Promise((resolve, reject) => {
        const extensoesPermitidas = ["image/png", "image/jpeg", "image/jpg"];

        if (!extensoesPermitidas.includes(file.type)) {
          reject(
            "Tipo de arquivo não suportado. Apenas PNG e JPG são permitidos."
          );
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          resolve({ default: reader.result });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });
  }
}
